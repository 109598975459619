import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { PERMISSION, useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useUserMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const selectedAffiliation = useStore((state) => state.selectedAffiliation)
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const updateUser = async (id, property, value, extra) => {
    const url = encodeURI(
      `${env.apiUrl}/affiliations/${selectedAffiliation.id}/users/${id}`
    )
    const body = {}
    body[property] = value
    if (property === 'permission' && value === PERMISSION.DENIED) {
      body.reason = extra.reason
      body.comment = extra.comment
    }
    const response = await securedFetch(url, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
    await handleResponse(response)
  }

  return useMutation(
    ({ id, property, value, extra }) => updateUser(id, property, value, extra),
    {
      onMutate: progressStart,
      onError: (error) => handleError(error, 'Error updating user permission'),
      onSuccess: () => {
        setMessage('Updated user permission')
        queryClient.invalidateQueries('users')
      },
      onSettled: progressEnd
    }
  )
}

export { useUserMutation }
