import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Container } from 'react-bootstrap'
import { ErrorBoundary } from 'react-error-boundary'
import TagManager from 'react-gtm-module'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { useAuth } from 'react-oauth2'
import {
  ErrorFallback,
  Footer,
  Header,
  HeaderCap,
  SkipToMainContent,
  Status
} from '../components'
import { env } from '../helpers'
import { useAffiliations, useCategories, useUser } from '../hooks'
import { PageFactory } from './PageFactory'
import { LoginPage, MasterAdminPage, RequestAccessPage } from '../pages'
import { PERMISSION, useStore } from '../store'
import { Tour } from './Tour'

const MANAGE_GROUPS = 'manage-groups'
const REQUEST_ACCESS = 'request-access'
const ACTIONS = [MANAGE_GROUPS, REQUEST_ACCESS]

const Main = ({ categoryRenderer, footerRenderer: FooterContent, steps }) => {
  const { affiliationUrlSlug, categoryUrlSlug } = useParams()
  const history = useHistory()
  const { login, logout, loggedIn } = useAuth()
  const disabled = useStore((state) => state.disabled)
  const setDocumentCategory = useStore((state) => state.setDocumentCategory)
  const setError = useStore((state) => state.setError)
  const setPermissions = useStore((state) => state.setPermissions)
  const selectedAffiliation = useStore((state) => state.selectedAffiliation)
  const setSelectedAffiliation = useStore(
    (state) => state.setSelectedAffiliation
  )
  const [selectedCategory, setSelectedCategory] = useState()
  const [options, setOptions] = useState([])
  const [links, setLinks] = useState([])
  const { affiliations } = useAffiliations()
  const { categories } = useCategories()
  const { user } = useUser()
  const [global, setGlobal] = useState(true)
  const [runTour, setRunTour] = useState(false)

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: { user_id: user ? user.userId : undefined }
    })
  }, [user])

  useEffect(() => {
    setPermissions(selectedAffiliation?.permissions || [])
  }, [selectedAffiliation, setPermissions])

  useEffect(() => {
    if (affiliations) {
      if (affiliationUrlSlug) {
        const affiliation = affiliations.find(
          (it) => it.urlSlug.toLowerCase() === affiliationUrlSlug.toLowerCase()
        )
        if (affiliation) {
          setSelectedAffiliation(affiliation)
        } else if (!loggedIn) {
          login(window.location)
        } else {
          setError(
            <>
              You do not have access to '{affiliationUrlSlug}'. Click{' '}
              <Alert.Link href={REQUEST_ACCESS}>here</Alert.Link> to request
              access.
            </>
          )
          history.push('/')
        }
      } else {
        setSelectedAffiliation(affiliations[0])
      }
    }
  }, [
    affiliationUrlSlug,
    affiliations,
    history,
    loggedIn,
    login,
    setError,
    setSelectedAffiliation
  ])

  useEffect(() => {
    if (selectedAffiliation && categories) {
      if (categoryUrlSlug) {
        const category = categories.find(
          (it) => it.urlSlug.toLowerCase() === categoryUrlSlug.toLowerCase()
        )
        if (category) {
          setSelectedCategory(category)
        } else if (!ACTIONS.includes(categoryUrlSlug)) {
          setError(`Category ${categoryUrlSlug} not found`)
        }
      } else {
        history.push(`/${selectedAffiliation.urlSlug}/${categories[0].urlSlug}`)
      }
    }
  }, [categories, categoryUrlSlug, history, selectedAffiliation, setError])

  useEffect(() => {
    categories &&
      setDocumentCategory(
        categories.find((it) => it.categoryType === 'DOCUMENT')
      )
  }, [categories, setDocumentCategory])

  useEffect(() => {
    setOptions(
      affiliations &&
        affiliations.map((item) => {
          let name = item.name
          if (item.permissions?.includes(PERMISSION.ADMIN)) {
            name += ' (admin)'
          } else if (item.permissions?.includes(PERMISSION.EDIT)) {
            name += ' (editor)'
          }
          return { ...item, name }
        })
    )
  }, [affiliations])

  useEffect(() => {
    setLinks(
      selectedAffiliation &&
        categories &&
        categories.map((c) => {
          const href = `/${selectedAffiliation.urlSlug}/${c.urlSlug}`
          return {
            url: href,
            name: c.name,
            props: { as: NavLink, to: href, active: false }
          }
        })
    )
  }, [categories, selectedAffiliation])

  useEffect(() => {
    setGlobal(
      categories && selectedCategory && categories[0] === selectedCategory
    )
  }, [categories, selectedCategory])

  const init = (url) => {
    setSelectedAffiliation(null)
    setSelectedCategory(null)
    history.push(url)
  }

  const onOptionSelect = (option) => {
    if (option.urlSlug !== affiliationUrlSlug) {
      init(`/${option.urlSlug}`)
    }
  }

  const onLogin = () => {
    init('/')
    login()
  }

  const userOptions = [
    { id: 1, name: 'Request Access' },
    { id: 2, name: 'Manage Account' }
  ]
  user?.roles?.includes('rit-portal-service-admin') &&
    userOptions.push({ id: 3, name: 'Manage Groups' })
  userOptions.push({ id: 4, name: 'Logout' })

  const onUserOptionSelect = (option) => {
    switch (option.name) {
      case 'Request Access':
        // if no affiliation, we should already be here
        if (affiliationUrlSlug) {
          history.push(`/${affiliationUrlSlug}/${REQUEST_ACCESS}`)
          setSelectedCategory(null)
        }
        break
      case 'Manage Account':
        window.open(env.accountUrl, '_blank', 'noopener,noreferrer')
        break
      case 'Manage Groups':
        history.push(`/${affiliationUrlSlug}/${MANAGE_GROUPS}`)
        setSelectedCategory(null)
        break
      case 'Logout':
        init('/')
        logout()
        break
      default:
        alert(`${option.name} is not handled`)
    }
  }

  const helpOptions = env.helpUrl ? [{ id: 1, name: 'Help Center' }] : []
  steps?.length && helpOptions.push({ id: 2, name: 'Show Page Tooltips' })
  env.contactUrl && helpOptions.push({ id: 3, name: 'Contact Us' })

  const onHelpOptionSelect = (option) => {
    switch (option.name) {
      case 'Help Center':
        window.open(env.helpUrl, '_blank', 'noopener,noreferrer')
        break
      case 'Show Page Tooltips':
        setRunTour(true)
        break
      case 'Contact Us':
        window.open(env.contactUrl, '_blank', 'noopener,noreferrer')
        break
      default:
        alert(`${option.name} is not handled`)
    }
  }

  // if not loggedIn, display LoginPage as first category
  const displaySelectedCategoryPage = () =>
    loggedIn || selectedCategory.id !== categories[0].id ? (
      <PageFactory category={selectedCategory} {...{ categoryRenderer }} />
    ) : (
      <LoginPage category={selectedCategory} onLogin={onLogin} />
    )

  // if we got back an empty list of affiliations, show the RequestAccessPage
  const displayNonCategoryPage = () => {
    if (affiliations?.length === 0 || categoryUrlSlug === REQUEST_ACCESS) {
      return <RequestAccessPage />
    } else if (categoryUrlSlug === MANAGE_GROUPS) {
      return <MasterAdminPage />
    } else {
      return null
    }
  }

  return (
    <Container className='app-container' fluid>
      <SkipToMainContent />
      <HeaderCap className='header-cap' />
      <Header
        className='header'
        expand='lg'
        collapseOnSelect
        bg='white'
        variant='light'
        disabled={disabled}
        options={loggedIn && selectedAffiliation ? options : null}
        selectedOption={selectedAffiliation}
        onOptionSelect={onOptionSelect}
        links={links}
        loggedIn={loggedIn}
        userName={user?.fullName}
        userOptions={userOptions}
        onUserOptionSelect={onUserOptionSelect}
        helpOptions={helpOptions}
        onHelpOptionSelect={onHelpOptionSelect}
      />
      <Container fluid>
        <Status />
        <main id='main-content'>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {selectedCategory
              ? displaySelectedCategoryPage()
              : displayNonCategoryPage()}
          </ErrorBoundary>
        </main>
        <Footer className='footer'>
          <FooterContent />
        </Footer>
        {selectedCategory && steps ? (
          <Tour
            category={selectedCategory}
            global={global}
            steps={steps}
            runTour={runTour}
            setRunTour={setRunTour}
          />
        ) : null}
      </Container>
    </Container>
  )
}

Main.propTypes = {
  categoryRenderer: PropTypes.elementType.isRequired,
  footerRenderer: PropTypes.elementType.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  )
}

export { Main }
