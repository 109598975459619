import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useSaveAffiliationMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const saveAffiliation = async (data) => {
    const url = encodeURI(
      data.id
        ? `${env.apiUrl}/affiliations/${data.id}`
        : `${env.apiUrl}/affiliations`
    )
    const response = await securedFetch(url, {
      method: data.id ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    await handleResponse(response)
    return response.json()
  }

  return useMutation(({ data }) => saveAffiliation(data), {
    onMutate: ({ data }) => {
      progressStart()
      if (data.id) {
        queryClient.cancelQueries('admin-categories')
        queryClient.setQueryData(['admin-categories', data.id], data.categories)
      }
    },
    onError: (error) => handleError(error, 'Error saving group'),
    onSuccess: (_, variables) => {
      const { data } = variables
      setMessage(
        `The group '${data.name}' was ${data.id ? 'updated' : 'created'}`
      )
      queryClient.invalidateQueries('affiliations')
      queryClient.invalidateQueries('admin-affiliations')
      queryClient.invalidateQueries('categories')
      queryClient.invalidateQueries('admin-categories')
    },
    onSettled: progressEnd
  })
}

export { useSaveAffiliationMutation }
