import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { ContentPanel } from '../container'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const SortableItem = ({ item, renderItem, draggable, className }) => {
  const {
    active,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: item.id, disabled: !draggable })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  active && active.id === item.id && (style.opacity = 0.5)

  return (
    <Row key={item.id}>
      <Col>
        <ContentPanel
          className={className}
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
        >
          {renderItem(item)}
        </ContentPanel>
      </Col>
    </Row>
  )
}

const PanelGrid = ({ items, renderItem, draggable, onDragEnd, className }) => {
  const [activeId, setActiveId] = useState()
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragStart = (event) => {
    const { active } = event
    setActiveId(active.id)
  }

  const handleDragEnd = (event) => {
    setActiveId(null)
    onDragEnd && onDragEnd(event)
  }

  return draggable && items.length > 1 ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items}>
        {items.map((item) => (
          <SortableItem
            key={item.id}
            {...{ item, renderItem, draggable, className }}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <ContentPanel className={className} style={{ height: '100%' }} />
        ) : null}
      </DragOverlay>
    </DndContext>
  ) : (
    items.map((item) => (
      <Row key={item.id}>
        <Col>
          <ContentPanel className={className}>{renderItem(item)}</ContentPanel>
        </Col>
      </Row>
    ))
  )
}

PanelGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ).isRequired,
  renderItem: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  className: PropTypes.string
}

export { PanelGrid }
