import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useSaveAccessRequestsMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const saveAccessRequests = async (accessRequests) => {
    const url = encodeURI(`${env.apiUrl}/accessRequests`)
    const response = await securedFetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(accessRequests)
    })
    await handleResponse(response)
  }

  return useMutation(
    ({ accessRequests }) => saveAccessRequests(accessRequests),
    {
      onMutate: progressStart,
      onError: (error) => handleError(error, 'Error submitting requests'),
      onSuccess: () => {
        queryClient.invalidateQueries('accessRequests')
        setMessage(
          'Access requests submitted. You will receive a confirmation email once your access has been activated.'
        )
      },
      onSettled: progressEnd
    }
  )
}

export { useSaveAccessRequestsMutation }
