import { useMutation } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useGetDocumentMutation = () => {
  const { loggedIn, securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setDisabled = useStore((state) => state.setDisabled)
  const setProgress = useStore((state) => state.setProgress)

  const getDocument = async (file) => {
    const fetchFn = loggedIn ? securedFetch : fetch
    const url = encodeURI(
      `${loggedIn ? env.apiUrl : env.publicApiUrl}/documents/${file.id}`
    )
    const response = await fetchFn(url)
    await handleResponse(response)
    const reader = response.body.getReader()
    let receivedLength = 0
    const chunks = []
    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        break
      }
      chunks.push(value)
      receivedLength += value.length
      setProgress((100 * receivedLength) / file.size)
    }
    return new Blob(chunks, { type: file.contentType })
  }

  return useMutation(({ file }) => getDocument(file), {
    onMutate: () => {
      setDisabled(true)
    },
    onError: (error) => handleError(error, 'Error downloading document'),
    onSettled: () => {
      setProgress(100)
      setDisabled(false)
    }
  })
}

export { useGetDocumentMutation }
