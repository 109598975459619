import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Joyride, { ACTIONS } from 'react-joyride'
import { useAuth } from 'react-oauth2'
import { TourCard } from '../components'
import { propTypes } from '../helpers'
import { useDisableTourMutation, useUser } from '../hooks'
import { useStore } from '../store'

const Tour = ({ category, global, steps, runTour, setRunTour }) => {
  const [filteredSteps, setFilteredSteps] = useState([])
  const [joyride, setJoyride] = useState()
  const { loggedIn } = useAuth()
  const applicationName = useStore((state) => state.applicationName)
  const imgSrcTour = useStore((state) => state.imgSrcTour)
  const pageState = useStore((state) => state.pageState)
  const permissions = useStore((state) => state.permissions)
  const { user } = useUser()

  const { mutate: setDisableTour } = useDisableTourMutation()

  const filter = useMemo(
    () => ({
      global,
      applicationName,
      categoryName: category.name,
      categoryType: category.categoryType,
      loggedIn,
      pageState,
      permissions
    }),
    [
      applicationName,
      category.categoryType,
      category.name,
      global,
      loggedIn,
      pageState,
      permissions
    ]
  )

  useEffect(() => {
    if (!loggedIn || (user && !user.disableTour)) {
      const { global, ...autoTourFilter } = filter
      const autoTourFlag = JSON.stringify(autoTourFilter)
      const seenAutoTour = localStorage.getItem(autoTourFlag)
      if (!seenAutoTour) {
        localStorage.setItem(autoTourFlag, 'AUTO-TOUR')
        setRunTour(true)
      }
    }
  }, [filter, loggedIn, setRunTour, user])

  useEffect(() => {
    setFilteredSteps(
      steps
        .filter((step) => {
          if (step.conditions === undefined) {
            return true
          }
          for (const [key, value] of Object.entries(step.conditions)) {
            if (key === 'permission') {
              if (!filter.permissions?.includes(value)) {
                return false
              }
            } else if (filter[key] !== value) {
              return false
            }
          }
          return true
        })
        .map(({ title, content, ...rest }) => {
          const regex = /<<(\w+)>>/g
          const matchFun = (match, capture) => filter[capture] || match
          return {
            ...(title
              ? {
                  title: title.replace(regex, matchFun)
                }
              : {}),
            content: content.replace(regex, matchFun),
            ...rest,
            disableBeacon: true
          }
        })
    )
  }, [filter, steps])

  const handleCallback = ({ action }) => {
    if (ACTIONS.CLOSE === action) {
      setRunTour(false)
      joyride.reset(true)
    }
  }

  return (
    <Joyride
      callback={handleCallback}
      continuous
      disableOverlayClose
      disableScrolling
      getHelpers={setJoyride}
      run={runTour}
      steps={filteredSteps}
      tooltipComponent={(props) => (
        <TourCard
          {...props}
          imgSrcTour={imgSrcTour}
          showDisableTour={loggedIn}
          disableTour={user?.disableTour}
          onDisableTour={(value) => setDisableTour({ value })}
        />
      )}
      styles={{
        options: {
          zIndex: 2000
        }
      }}
    />
  )
}

Tour.propTypes = {
  category: propTypes.categoryPropType,
  global: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ).isRequired,
  runTour: PropTypes.bool,
  setRunTour: PropTypes.func
}

export { Tour }
