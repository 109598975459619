import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useRenameDocumentMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setDisabled = useStore((state) => state.setDisabled)
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const renameDocument = async (documentId, path) => {
    const url = encodeURI(`${env.apiUrl}/documents/${documentId}`)
    const response = await securedFetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ path: path })
    })
    await handleResponse(response)
  }

  return useMutation(
    ({ documentId, path }) => renameDocument(documentId, path),
    {
      onMutate: () => {
        progressStart()
        setDisabled(true)
      },
      onError: (error) => handleError(error, 'Error renaming document'),
      onSuccess: () => {
        queryClient.invalidateQueries('documents')
        setMessage('Document was renamed')
      },
      onSettled: () => {
        progressEnd()
        setDisabled(false)
      }
    }
  )
}

export { useRenameDocumentMutation }
