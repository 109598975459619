import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { dataToDocument } from './dataToDocument'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useDocuments = () => {
  const { loggedIn, securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const documentCategory = useStore((state) => state.documentCategory)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const selectedAffiliation = useStore((state) => state.selectedAffiliation)
  const categoryId = documentCategory && documentCategory.id

  const getDocuments = async () => {
    const fetchFn = loggedIn ? securedFetch : fetch
    const url = encodeURI(
      `${
        loggedIn ? env.apiUrl : env.publicApiUrl
      }/categories/${categoryId}/documents`
    )
    const response = await fetchFn(url)
    await handleResponse(response)
    const data = await response.json()
    return data.map((item) =>
      dataToDocument(
        item,
        selectedAffiliation.urlSlug,
        documentCategory.urlSlug
      )
    )
  }

  const query = useQuery(['documents', categoryId], getDocuments, {
    enabled: !!categoryId
  })

  useEffect(() => {
    if (query.status === 'loading') {
      progressStart()
    } else if (query.status === 'success') {
      progressEnd()
    } else if (query.status === 'error') {
      handleError(query.error, 'Error getting documents')
      progressEnd()
    }
  }, [handleError, progressEnd, progressStart, query.error, query.status])

  return {
    documents: query.data
  }
}

export { useDocuments }
