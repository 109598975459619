import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useSortStoryMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const queryClient = useQueryClient()

  const sortStory = async (storyId, order) => {
    const url = encodeURI(`${env.apiUrl}/stories/${storyId}`)
    const response = await securedFetch(url, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ orderNumber: order })
    })
    await handleResponse(response)
  }

  const arrayMove = (array, from, to) => {
    const newArray = array.slice()
    newArray.splice(
      to < 0 ? newArray.length + to : to,
      0,
      newArray.splice(from, 1)[0]
    )
    return newArray
  }

  // 'categoryId' should also be passed. It is not used by the mutating
  // function but is used by the state handlers below to update the cache
  return useMutation(({ storyId, order }) => sortStory(storyId, order), {
    onMutate: ({ categoryId, storyId, order }) => {
      queryClient.cancelQueries(['stories', categoryId])
      queryClient.setQueryData(['stories', categoryId], (stories) => {
        const from = stories.findIndex((story) => story.id === storyId)
        const to = stories.findIndex((story) => story.orderNumber === order)
        return arrayMove(stories, from, to)
      })
    },
    onError: (error) => handleError(error, 'Error sorting stories'),
    onSettled: (data, error, { categoryId }) => {
      queryClient.invalidateQueries(['stories', categoryId])
    }
  })
}

export { useSortStoryMutation }
